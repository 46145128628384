import Vue from 'vue'
import axios from 'axios'
import api from "./api"
import qs from 'qs'

// // 网站地址
// axios.defaults.webURL = process.env.webURL;
// 接口请求地址
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL


axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

axios.defaults.timeout = 200000;

// 添加请求拦截器(axios请求前)
axios.interceptors.request.use(config => {
  // let token = localStorage.getItem('token') || sessionStorage.getItem('token') || '';
  let token = localStorage.getItem('tokenLogin') || sessionStorage.getItem('tokenLogin') || '';
  let uid = localStorage.getItem('uid') || '';
  let timestamp = localStorage.getItem('timestamp') || '';
  if (token) {
    config.headers['token'] = token;
  }
  if (uid) {
    config.headers['uid'] = uid;
  }
  if (timestamp) {
    config.headers['timestamp'] = timestamp;
  }
  if (config.method === 'get') { //如果是get请求，且params是数组类型如arr=[1,2]，则转换成arr=1&arr=2 
    config.paramsSerializer = function (params) { return qs.stringify(params, { arrayFormat: 'repeat' }) }
  }
  return config;
}, error => {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器(axios请求后)
axios.interceptors.response.use(response => {
  // 对响应数据做点什么
  if (response.data.errcode == 404 || response.data.errmsg == "数据不存在" || response.data.errmsg == "艺人ID错误") {
    location.href = '/error404'
    return false
  }
  // 101：Token不能为空
  // 102：时间戳不正确
  // 103：用户ID不能为空
  // 104：Token超时
  // 105：Token错误、无效的Token（刷新接口才会出现无效token）
  // 106：用户ID错误
  if (response.data.errcode == 101 || response.data.errcode == 102 || response.data.errcode == 103 || response.data.errcode == 104 || response.data.errcode == 105 || response.data.errcode == 106) {
    localStorage.clear()
    // localStorage.removeItem('token')
    // localStorage.removeItem('tokenLogin')
    // localStorage.removeItem('timestamp')
    // localStorage.removeItem('uid')
    // localStorage.removeItem('hasPhoneNumber')
  }
  if (response.data.meta != null) {
    return response.data
  } else {
    return response
  }
}, error => {
  // 对响应错误做点什么
  return Promise.reject(error);
});
const postData = (url, data, config = {}) => {
  // return axios.post(api[url], qs.stringify(data));
  return axios.post(api[url], data, config);
}
//  /movie/in_theaters
const getData = (url, params) => {
  return axios.get(api[url], {
    params
  });
}
const postUrl = (url, path, data, config = {}) => {
  // return axios.post(api[url], qs.stringify(data));
  return axios.post(api[url] + path, data, config).catch(function (error) {
  });
}
const getUrl = (url, path, params) => {
  return axios.get(api[url] + path, {
    params
  });
}
Vue.prototype.$postData = postData;
Vue.prototype.$postUrl = postUrl;
Vue.prototype.$getData = getData;
Vue.prototype.$getUrl = getUrl;
Vue.prototype.$axios = axios;

export default {
  postData, getData, postUrl, getUrl, axios
};
